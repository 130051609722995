import { MaterialModule } from './../../modules/material/material.module';
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, timer, takeWhile, switchMap, takeUntil } from 'rxjs';
import { OrderService } from '../../services/order.service';
import { CommonModule } from '@angular/common';
import { AdyenCheckoutStatusResponse } from '../../models/adyen-checkout-status-response';
import { environment } from '../../../environments/environment';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Performance } from '@angular/fire/performance';

@Component({
  selector: 'app-success-page',
  standalone: true,
  imports: [MaterialModule, CommonModule],
  templateUrl: './success-page.component.html',
  styleUrl: './success-page.component.scss',
})
export class SuccessPageComponent {
  supportEmail = 'hello@afferentsoftware.com';

  private analytics: Analytics = inject(Analytics);
  private performance: Performance = inject(Performance);
  
  transactionId = '';
  adyenSessionId = '';
  adyensessionResult = '';
  responseStatus = '';
  orderNum = '';
  pin = '';
  isPaymentReceived = false;
  isCheckoutExpired = false;
  isTimeOut = false;
  counter = 0;
  MAX_COUNTER = 12; // 1 minutes (12 * 5000) - Repeat 12 times every 5 sec
  private adyenSub = new Subject<void>();
  originatorId = environment.originatorId;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.transactionId = params['transactionId'];
      this.adyenSessionId = params['sessionId'];
      this.adyensessionResult = params['sessionResult'];
      console.log(this.transactionId);
    });

    let adyenSessionCounter = 0;
    timer(0, 5000)
      .pipe(
        takeWhile(() => adyenSessionCounter < this.MAX_COUNTER + 1),
        switchMap(() =>
          this.orderService.getAdyenCheckoutStatus(
            this.adyenSessionId,
            this.adyensessionResult,
            this.transactionId,
            this.originatorId
          )
        ),
        takeUntil(this.adyenSub)
      )
      .subscribe({
        next: (response: AdyenCheckoutStatusResponse) => {
          console.log(response);
          this.counter = this.counter + 1;
          console.log('counter: ' + this.counter);
          console.log('Checkout status: ' + response.status);
          if (response.status === 'canceled' || response.status === 'expired') {
            this.adyenSub.next();
            this.adyenSub.complete();
            this.router.navigateByUrl('cancel');
          } else if (response.status === 'received') {
            this.adyenSub.next();
            this.adyenSub.complete();
            this.orderNum = response.orderNum;
            this.pin = response.pin;
            this.isPaymentReceived = true;
            this.isCheckoutExpired = false;
            this.logAnalyticEvent(response);
          } else if (response.status === 'hidden') {
            this.isPaymentReceived = true;
            this.isCheckoutExpired = true;
            this.adyenSub.next();
            this.adyenSub.complete();
          } else if (this.counter > this.MAX_COUNTER) {
            this.isTimeOut = true;
            this.adyenSub.next();
            this.adyenSub.complete();
          }
        },
        error: (error: any) => {
          console.error(error);
          this.counter = this.counter + 1;
        },
      });
  }

  navigateToHome() {
    this.router.navigateByUrl('home');
  }

  ngOnDestroy(): void {
    this.adyenSub.next();
    this.adyenSub.complete();
  }

  logAnalyticEvent(response: AdyenCheckoutStatusResponse){
    logEvent(this.analytics, "purchase", {
      transaction_id: this.transactionId,
      currency: response.currencyPaid,
      value: response.amountPaid,
      shipping: 0,
      tax: 0,
      items: [{
        item_id: response.type,
        item_name: response.type,
        item_category: 'FX',
        item_category2: response.type,
        price: response.rate,
        quantity: response.quantity
      }]
    });
  }
}
